import React, { Suspense,lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import App from "../App";
import Loader from "../modules/Lobby/Loader";
import Container from 'react-bootstrap/Container';
const Jackpot = lazy(() => import('../modules/Jackpot/Container/Jackpot'));
const JackpotOTP = lazy(() => import('../modules/Jackpot/Container/JackpotOTP'));
const JackpotTracking = lazy(() => import('../modules/Jackpot/Container/JackpotTracking'));
const Quickbet = lazy(() => import('../modules/Quickbet/Container/Quickbet'));
const QuickbetOTP = lazy(() => import('../modules/Quickbet/Container/QuickbetOTP'));
const QuickbetTracking = lazy(() => import('../modules/Quickbet/Container/QuickbetTracking'));
const Quicktip = lazy(() => import('../modules/Quicktip/Container/Quicktip'));
const QuicktipOTP = lazy(() => import('../modules/Quicktip/Container/QuicktipOTP'));
const QuicktipRegistred = lazy(() => import('../modules/Quicktip/Container/QuicktipTracking'));
const Smartbet = lazy(() => import('../modules/Smartbet/Container/Smartbet'));
const SmartbetGenerated = lazy(() => import('../modules/Smartbet/Container/SmartbetGenerated'));
const SmartbetTracking = lazy(() => import('../modules/Smartbet/Container/SmartbetTracking'));
const SmartbetOTP = lazy(() => import('../modules/Smartbet/Container/SmartbetOTP'));
const Superbet = lazy(() => import('../modules/Superbet/Containers/Superbet'));
const SuperbetOTP = lazy(() => import('../modules/Superbet/Containers/SuperbetOTP'));
const SuperbetTracking = lazy(() => import('../modules/Superbet/Containers/SuperbetTracking'));
const TicketResults = lazy(() => import('../modules/TicketHistory/Containter/TicketHistory'));
const Error404 = lazy(() => import('../modules/Errors/Error404'));

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            }>
                <Route index element={<Container>Home Page Content</Container>} />
            </Route>
            <Route path="/jackpot/:id" element={
                <Suspense fallback={<Loader />}>
                    <Jackpot />
                </Suspense>
            } />
            <Route path="/jackpots/otp" element={
                <JackpotOTP />
            } />
            <Route path="/jackpot/tracking/:id" element={<JackpotTracking />} />
            <Route path="/quicktip/:id" element={
                <Suspense fallback={<Loader />}>
                    <Quicktip />
                </Suspense>
            } />
            <Route path="/quicktip/otp" element={<QuicktipOTP />} />
            <Route path="/quicktips/tracking/:id" element={<QuicktipRegistred />} />
            <Route path="/smartbet/:id" element={
                <Suspense fallback={<Loader />}>
                    <Smartbet />
                </Suspense>
            } />
            <Route path="/smartbet/generated" element={<SmartbetGenerated />} />
            <Route path="/smartbet/otp" element={<SmartbetOTP />} />
            <Route path="/smartbet/tracking/:id" element={
                    <SmartbetTracking />
            } />
            <Route path="/superbet/:id" element={
                <Suspense fallback={<Loader />}>
                    <Superbet />
                </Suspense>
            } />
            <Route path="/superbet/otp" element={<SuperbetOTP />} />
            <Route path="/quickbet/:id" element={
                <Suspense fallback={<Loader />}>
                    <Quickbet />
                </Suspense>
            } />
            <Route path="/quickbet/otp" element={<QuickbetOTP />} />
            <Route path="/quickbet/tracking/:id" element={
                <QuickbetTracking />
            } />
            <Route path="/superbet/tracking/:id" element={
                    <SuperbetTracking />
            } />
            <Route path="/track/:ticketCode" element={
                <Suspense fallback={<Loader />}>
                    <TicketResults />
                </Suspense>
            } />
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
};

export { PrivateRoutes };
